import React, {Component} from 'react';
import './FifthSegment.scss';
import VisibilitySensor from "react-visibility-sensor";
import {ReactComponent as IconStar} from "../../../../assets/icons/star.svg";
import {ReactComponent as IconQuote} from "../../../../assets/icons/quote.svg";

class FifthSegment extends Component {

    quotes = () => <IconQuote/>

    stars = () => (
        <div className="stars">
            {Array.from({ length: 5 - 1 + 1 }, (_, i) => i).map(i =>
                <div className="star" key={i}>
                    <IconStar/>
                </div>)
            }
        </div>
    )

    visibilityChange = (visible) => {
        this.props.visibilityChange(visible, this.ref.id)
    }

    render() {
        return (
            <React.Fragment>
                <VisibilitySensor onChange={this.visibilityChange}>
                    <div className="page-segment fifth-segment" id="fifth-segment" ref={ref => this.ref = ref}>
                    <div className="container">
                        <h4>Garagisten über den Service-Rechner</h4>

                        <p className="description">
                            Von Grossbetrieben mit bis zu 20 Mitarbeitern wie auch Einmannbetrieben - der Service-Rechner ist eine optimale Lösung für die Leistungssteigerung und Kundenakquise.
                        </p>

                        <div className="reviews">
                            <div className="review">
                                <div className="owner-quotes-item">
                                    <div className="owner-quotes-item__quote">{this.quotes()}</div>
                                    <span>»Ich habe Jahre nach genau so einem Tool gesucht. Der Service-Rechner von Carhelper ist die Lösung und gehört auf jede Garagen-Webseite.«</span>
                                </div>
                                <div className="review-item">
                                    <div className="review-item-stars">
                                        <div className="review-item-stars__item">
                                            {this.stars()}
                                        </div>
                                    </div>
                                    <div className="review-item-names">
                                        <div>Hans Mäschli</div>
                                        <div>Mäschli Kreuzgarage</div>
                                    </div>
                                </div>
                            </div>
                            <div className="review">
                                <div className="owner-quotes-item">
                                    <div className="owner-quotes-item__quote">{this.quotes()}</div>
                                    <span>»Wir benötigen bis zu einer halben Stunde pro Offerte. Dank dem Service-Rechner haben wir weniger als 5 Minuten und sparen dadurch wertvolle Zeit.«</span>
                                </div>
                                <div className="review-item">
                                    <div className="review-item-stars">
                                        <div className="review-item-stars__item">
                                            {this.stars()}
                                        </div>
                                    </div>
                                    <div className="review-item-names">
                                        <div>Sibylle Mäder</div>
                                        <div>Garage Mäder</div>
                                    </div>
                                </div>
                            </div>
                            <div className="review">
                                <div className="owner-quotes-item">
                                    <div className="owner-quotes-item__quote">{this.quotes()}</div>
                                    <span>»Es war erstaunlich einfach. Es hat mich wirklich begeistert, dass mit sehr wenigen Angaben eine so zuverlässige Kalkulation   sichergestellt werden kann.«</span>
                                </div>
                                <div className="review-item">
                                    <div className="review-item-stars">
                                        <div className="review-item-stars__item">
                                            {this.stars()}
                                        </div>
                                    </div>
                                    <div className="review-item-names">
                                        <div>Stefan Hiltmann</div>
                                        <div>Altenburg Garage</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </VisibilitySensor>
            </React.Fragment>
        )
    }
}

export default FifthSegment;
