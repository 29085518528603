import React, {Component} from 'react';
import './NinthSegment.scss';
import Button from "../../../shared/Button/Button";
import VisibilitySensor from "react-visibility-sensor";
import axios from '../../../../app/config/axios';
import parseResponse from "../../../../utils/parseResponse";
import contactFormModel from "../../../../store/models/contact-form";

const API_CONTACT_FORM_URL = 'contact-form'

class NinthSegment extends Component {

    state = {
        form: contactFormModel,
        submitting: false,
        formMessage: '',
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.formMessage !== this.state.formMessage) {
            setTimeout(() => this.setState({formMessage: ''}), 8000)
        }
    }

    visibilityChange = (visible) => {
        this.props.visibilityChange(visible, this.ref.id)
    }

    submit = (e) => {
        e.preventDefault();

        this.setState({submitting: true, formMessage: ''})

        axios.post(API_CONTACT_FORM_URL, this.state.form)
            .then((response) => {
                this.setState({submitting: false, formMessage: response.data.message, form: contactFormModel})
            })
            .catch((error) => {
                this.setState({submitting: false, formMessage: parseResponse(error.response)})
            });
    }

    change = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
    }

    render() {
        const {submitting, formMessage, form} = this.state;

        return (
            <React.Fragment>
                <VisibilitySensor onChange={this.visibilityChange}>
                    <div className="page-segment ninth-segment" id="ninth-segment" ref={ref => this.ref = ref}>
                        <div className="container">
                            <h4>Kontaktformular</h4>
                            <p className="description">Kontaktieren Sie uns für mehr Informationen und einen
                                unverbindlichen Test des Service-Rechners.</p>

                            <form method="POST" onSubmit={this.submit} name="contactForm">
                                <div className="form">
                                    <div className="form-group">
                                        <div className="relative">
                                            <input type="text" name="garage" value={form.garage}
                                                   placeholder="Ihre Garage*" className="form-control" required={true}
                                                   onChange={this.change}/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="relative">
                                            <input type="text" name="name" value={form.name}
                                                   placeholder="Ihr Vor- und Nachname*" className="form-control"
                                                   required={true} onChange={this.change}/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="relative">
                                            <input type="text" name="email" value={form.email}
                                                   placeholder="Ihre E-Mail*" className="form-control" required={true}
                                                   onChange={this.change}/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="relative">
                                            <input type="text" name="phone" value={form.phone}
                                                   placeholder="Ihre Telefonnummer" className="form-control"
                                                   onChange={this.change}/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="relative">
                                            <textarea name="subject" id="subject" cols="30" rows="6"
                                                      placeholder="Möchten Sie uns etwas mitteilen?"
                                                      onChange={this.change} value={form.subject}/>
                                        </div>
                                    </div>
                                    <div className="submit">
                                        <Button size="lg" type="primary" extraClass="btn-border" disabled={submitting}>
                                            {submitting ? 'Wird bearbeitet' : 'Senden'}
                                        </Button>
                                    </div>
                                    {formMessage !== '' &&
                                    <div className="text-center mt-30 submitted-form">
                                        {formMessage}
                                    </div>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </VisibilitySensor>
            </React.Fragment>
        )
    }
}

export default NinthSegment;
