import Home from "../views/Home/Home";
import Impressum from "../views/Impressum/Impressum";
import Register from "../views/Register/Register";

export const routes = {
    home: {
        name: 'Home',
        path: '/',
        component: Home,
        exact: true,
        pageTitle: 'HOME | Service Rechner',
    },
    impressum: {
        name: 'Impressum',
        path: '/impressum',
        component: Impressum,
        exact: true,
        pageTitle: 'Impressum | Service Rechner',
    },
    register: {
        name: 'Register',
        path: '/register',
        component: Register,
        exact: true,
        pageTitle: 'Register | Service Rechner',
    },
};

