export const parseResponse = response => {
    if (!response) {
        return ''
    }

    if (!response.hasOwnProperty('data')) {
        return ''
    }
    if (response.data.hasOwnProperty('errors') && typeof response.data.errors === 'object') {
        return response.data.errors[Object.keys(response.data.errors)[0]][0]
    }

    if (response.data.hasOwnProperty('message')) {
        return response.data.message
    }

    return ''
}

export default parseResponse;
