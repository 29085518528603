import React, {Component} from 'react';
import './SecondSegment.scss';
import icon1 from "../../../../assets/images/second-segment/icon-1.webp";
import icon2 from "../../../../assets/images/second-segment/icon-2.webp";
import icon3 from "../../../../assets/images/second-segment/icon-3.webp";
import icon4 from "../../../../assets/images/second-segment/icon-4.webp";
import icon5 from "../../../../assets/images/second-segment/icon-5.webp";
import icon6 from "../../../../assets/images/second-segment/icon-6.webp";
import icon7 from "../../../../assets/images/second-segment/icon-7.webp";
import icon8 from "../../../../assets/images/second-segment/icon-8.webp";
import icon9 from "../../../../assets/images/second-segment/icon-9.webp";
import VisibilitySensor from "react-visibility-sensor";

class SecondSegment extends Component {

    visibilityChange = (visible) => {
        this.props.visibilityChange(visible, this.ref.id)
    }

    render() {
        return (
            <React.Fragment>
                <VisibilitySensor onChange={this.visibilityChange}>
                    <div className="page-segment second-segment" id="second-segment" ref={ref => this.ref = ref}>
                    <div className="container">
                        <h4>Ihre Vorteile</h4>
                        <p className="description">Mit dem Service-Rechner erleichtern Sie Ihren Mitarbeitern und Kunden sowie sich selbst den Alltag.</p>

                        <div className="advantages-list">
                            <div className="row">
                                <div className="col-lg-4 col-xs-12">
                                    <div className="advantages-list__img"><img src={icon1} alt="icon1"/></div>
                                    <h6>Zeitersparnis</h6>
                                    <p>Ihre Kunden und Mitarbeiter generieren jederzeit selbständig akkurate Offerten und sparen dadurch wertvolle Zeit.</p>
                                </div>
                                <div className="col-lg-4 col-xs-12">
                                    <div className="advantages-list__img"><img src={icon2} alt="icon2"/></div>
                                    <h6>Transparenz</h6>
                                    <p>Durch die einfache intuitive Nutzung des Service-Rechners punkten Sie bei all Ihren Kunden und erzeugen durch die schnelle Offertengenerierung einen WOW-Effekt.</p>
                                </div>
                                <div className="col-lg-4 col-xs-12">
                                    <div className="advantages-list__img"><img src={icon3} alt="icon3"/></div>
                                    <h6>Neukunden</h6>
                                    <p>Sie gewinnen online durch den Service-Rechner einfach Neukunden aus dem schweizweiten Netzwerk von über 6 Millionen Internetnutzern.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-xs-12">
                                    <div className="advantages-list__img"><img src={icon4} alt="icon4"/></div>
                                    <h6>24/7 erreichbar</h6>
                                    <p>Rund um die Uhr können Offerten erstellt werden. Dadurch vereinfachen Sie bestehenden und potentiellen  Neukunden die Buchung bei Ihrer Garage.</p>
                                </div>
                                <div className="col-lg-4 col-xs-12">
                                    <div className="advantages-list__img"><img src={icon5} alt="icon5"/></div>
                                    <h6>Aufwandreduzierung</h6>
                                    <p>Reduzieren Sie Ihren Aufwand und nutzen Sie die gewonnene Zeit statt dessen lieber für produktives Arbeiten.</p>
                                </div>
                                <div className="col-lg-4 col-xs-12">
                                    <div className="advantages-list__img"><img src={icon6} alt="icon6"/></div>
                                    <h6>Auf allen Geräten</h6>
                                    <p>Noch immer steigt die Nutzung der mobilen Geräte wie Tablets und Natel. Umso wichtiger ist es auf allen Geräten verfügbar zu sein. </p>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-xs-12">
                                    <div className="advantages-list__img"><img src={icon7} alt="icon7"/></div>
                                    <h6>Servicepläne</h6>
                                    <p>Sie erhalten mit jeder Offerte korrekte Teilepreise- und nummern sowie akkurate Servicepläne.</p>
                                </div>
                                <div className="col-lg-4 col-xs-12">
                                    <div className="advantages-list__img"><img src={icon8} alt="icon8"/></div>
                                    <h6>Preis-Leistungs-Verhältnis</h6>
                                    <p>Der Service-Rechner ist eines der preiswertesten Produkte im Markt und bietet Ihnen ein einfach zu bedienendes Tool mit akkuraten Daten.</p>

                                </div>
                                <div className="col-lg-4 col-xs-12">
                                    <div className="advantages-list__img"><img src={icon9} alt="icon9"/></div>
                                    <h6>Ein Schritt voraus</h6>
                                    <p>Mit dem Service-Rechner sind Sie Ihren Mitbewerbern ein Schritt voraus und bieten Ihren Kunden einen Rundum-Service.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </VisibilitySensor>
            </React.Fragment>
        )
    }
}

export default SecondSegment;
