import axios from 'axios'
import {language} from "../../utils/language";

const instance = axios.create({
    baseURL: process.env.REACT_APP_B2B_BASE_URL_API,
});

instance.interceptors.request.use(config => {

    config.headers.common.Language = language()

    return config
});

export default instance
