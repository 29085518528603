import React, {Component} from 'react';
import "./Impressum.scss";
import Header from "../../components/layout/Header/Header";
import Footer from "../../components/layout/Footer/Footer";
import {Helmet} from "react-helmet";
import {GApageView} from "../../utils/GApageView";

class Impressum extends Component {

    componentDidMount() {
        GApageView('/impressum');
    }

    render() {
        return (
            <React.Fragment>
                <Helmet><title>{this.props.title}</title></Helmet>

                <Header/>

                <div className="container">
                    <div className="impressum">
                        <h1>Impressum</h1>

                        <p>
                            <span>Service-Rechner</span>
                            <span>c/o Carhelper AG</span>
                            <span>Promenadengasse 18</span>
                            <span>8001 Zürich</span>
                        </p>

                        <p>info@service-rechner.ch</p>
                    </div>
                </div>

                <Footer/>
            </React.Fragment>
        )
    }
}

export default Impressum;
