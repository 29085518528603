import React, {Component} from 'react';
import './Header.scss';
import { Link } from 'react-scroll'
import {isMobileOnly} from "react-device-detect";
import {routes} from "../../../routes/routes";
import {Link as LinkRouter, matchPath} from "react-router-dom";

const logo = isMobileOnly ? require("../../../assets/images/logo-big.webp") : require("../../../assets/images/logo.webp");

class Header extends Component {

    state = {
        link: {
            offset: -79, smooth: true, duration: 500
        },
        visible: true
    }

    componentDidMount() {
        if(!isMobileOnly){
            window.addEventListener('scroll', (e) => {
                const window = e.currentTarget;

                if(window.scrollY < 50){
                    this.setState({
                        visible: true
                    })
                    return;
                }

                this.setState({
                    visible:  this.prev > window.scrollY
                })

                this.prev = window.scrollY;
            })
        }
    }

    render() {
        const {visible} = this.state;
        const {currentSegment} = this.props;

        return (
            <React.Fragment>
                <header>
                    <div className={`navigation ${!visible ? 'invisible' : ''}`}>
                        <div className="container">
                            <div className="navigation-header">

                                <div className="navigation-header-logo">
                                    <LinkRouter to={routes.home.path}><img src={logo} alt="Service Rechner"/></LinkRouter>
                                </div>

                                <div className="navigation-header-links">
                                    {matchPath(window.location.pathname, routes.home) ?
                                        <Link className={`navigation-header-links__item ${currentSegment === 'root' ? 'active' : ''}`} to="root" offset={this.state.link.offset} smooth={this.state.link.smooth} duration={this.state.link.duration}>
                                            HOME
                                        </Link>
                                        :
                                        <LinkRouter className={`navigation-header-links__item ${currentSegment === 'root' ? 'active' : ''}`} to={routes.home.path}>HOME</LinkRouter>
                                    }
                                    {matchPath(window.location.pathname, routes.home) ?
                                        <Link
                                            className={`navigation-header-links__item ${currentSegment === 'first-segment' ? 'active' : ''}`}
                                            to="first-segment" offset={this.state.link.offset}
                                            smooth={this.state.link.smooth} duration={this.state.link.duration}>
                                            SERVICE-RECHNER
                                        </Link>
                                        :
                                        <LinkRouter className={`navigation-header-links__item ${currentSegment === 'root' ? 'active' : ''}`} to={routes.home.path}>SERVICE-RECHNER</LinkRouter>
                                    }
                                    {matchPath(window.location.pathname, routes.home) ?
                                        <Link className={`navigation-header-links__item ${currentSegment === 'third-segment' ? 'active' : ''}`} to="third-segment" offset={this.state.link.offset} smooth={this.state.link.smooth} duration={this.state.link.duration}>
                                            SO FUNKTIONIERTS
                                        </Link>
                                        :
                                        <LinkRouter className={`navigation-header-links__item ${currentSegment === 'root' ? 'active' : ''}`} to={routes.home.path}>SO FUNKTIONIERTS</LinkRouter>
                                    }
                                    {matchPath(window.location.pathname, routes.home) ?
                                        <Link className={`navigation-header-links__item ${currentSegment === 'sixth-segment' ? 'active' : ''}`} to="sixth-segment" offset={this.state.link.offset} smooth={this.state.link.smooth} duration={this.state.link.duration}>
                                            ÜBER UNS
                                        </Link>
                                        :
                                        <LinkRouter className={`navigation-header-links__item ${currentSegment === 'root' ? 'active' : ''}`} to={routes.home.path}>ÜBER UNS</LinkRouter>
                                    }
                                    {matchPath(window.location.pathname, routes.home) ?
                                        <Link className={`navigation-header-links__item ${currentSegment === 'fifth-segment' ? 'active' : ''}`} to="fifth-segment" offset={this.state.link.offset} smooth={this.state.link.smooth} duration={this.state.link.duration}>
                                            KUNDENMEINUNGEN
                                        </Link>
                                        :
                                        <LinkRouter className={`navigation-header-links__item ${currentSegment === 'root' ? 'active' : ''}`} to={routes.home.path}>KUNDENMEINUNGEN</LinkRouter>
                                    }
                                    {matchPath(window.location.pathname, routes.home) ?
                                        <Link className={`navigation-header-links__item ${currentSegment === 'ninth-segment' ? 'active' : ''}`} to="ninth-segment" offset={this.state.link.offset} smooth={this.state.link.smooth} duration={this.state.link.duration}>
                                            KONTAKT
                                        </Link>
                                        :
                                        <LinkRouter className={`navigation-header-links__item ${currentSegment === 'root' ? 'active' : ''}`} to={routes.home.path}>KONTAKT</LinkRouter>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </React.Fragment>
        )
    }
}

export default Header;
