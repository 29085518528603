import React, {Component} from 'react';
import './SeventhSegment.scss';
import VisibilitySensor from "react-visibility-sensor";

class SeventhSegment extends Component {

    visibilityChange = (visible) => {
        this.props.visibilityChange(visible, this.ref.id)
    }

    render() {
        return (
            <React.Fragment>
                <VisibilitySensor onChange={this.visibilityChange}>
                    <div className="page-segment seventh-segment" id="seventh-segment" ref={ref => this.ref = ref}>
                        <div className="container">
                            <h4>Service-Rechner Fakten</h4>

                            <div className="statistic">
                                <div className="statistic-item">
                                    <div className="statistic-item__number">60+</div>
                                    <div className="statistic-item__name">Garagen in der Schweiz</div>
                                </div>
                                <div className="statistic-item">
                                    <div className="statistic-item__number">180h</div>
                                    <div className="statistic-item__name">Zeitersparnis pro Monat</div>
                                </div>
                                <div className="statistic-item">
                                    <div className="statistic-item__number">500+</div>
                                    <div className="statistic-item__name">Offerten pro Monat</div>
                                </div>
                                <div className="statistic-item">
                                    <div className="statistic-item__number">3h</div>
                                    <div className="statistic-item__name">Zeitersparnis pro Betrieb</div>
                                </div>
                                <div className="statistic-item">
                                    <div className="statistic-item__number">75+</div>
                                    <div className="statistic-item__name">Anfragen pro Monat</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </VisibilitySensor>
            </React.Fragment>
        )
    }
}

export default SeventhSegment;
