import React, {Component} from 'react';
import "./Register.scss";
import Header from "../../components/layout/Header/Header";
import Footer from "../../components/layout/Footer/Footer";
import {Helmet} from "react-helmet";
import SimpleReactValidator from 'simple-react-validator';
import Button from "../../components/shared/Button/Button";
import Input from "../../components/shared/Input/Input";
import axios from "../../app/config/axios";
import {ReactComponent as IconCheck} from "../../assets/icons/check.svg"
import {ReactComponent as IconClose} from "../../assets/icons/close.svg"
import ZipPicker from "../../components/shared/ZipPicker/ZipPicker";
import InputFile from "../../components/shared/InputFile/InputFile";
import Alert from "../../components/shared/Alert/Alert";
import parseResponse from "../../utils/parseResponse";
import registerFormModel from "../../store/models/register";
import {GApageView} from "../../utils/GApageView";

const API_REPAIR_SHOP_SLUG_URL = 'repair-shop-slug';
const API_GET_COORDINATES_URL = 'get-coordinates';
const API_REGISTER_URL = 'register-repair-shop';

const LOGO = {
    FILE_SIZE: 10485760,
    SUPPORTED_FORMATS: ['image/jpg', 'image/jpeg', 'image/png'],
}

const RegisterModel = {
    form: registerFormModel,
    slugSample: '',
    slugAvailable: null,
    zip: {
        label: ''
    },
    alertSuccess: [],
    alertErrors: [],
    submitting: {
        register: false
    },
    logo: null
}

class Register extends Component {

    constructor(props) {
        super(props);

        this.state = RegisterModel;

        this.messageRef = React.createRef()

        this.validator = new SimpleReactValidator({
            locale: 'de',
            autoForceUpdate: this,
            validators: {
                image: {
                    message: ':attribute muss ein Bild sein.',
                    rule: (val) => {
                        return LOGO.SUPPORTED_FORMATS.includes(val.type)
                    },
                },
                size: {
                    message: ':attribute muss kleiner sein ' + Math.round(LOGO.FILE_SIZE / 1024 / 1024) + 'MB',
                    rule: (val) => {
                        return val.size <= LOGO.FILE_SIZE;
                    },
                }
            }
        });
    }

    componentDidMount() {
        GApageView('/register');
    }

    updateFormField = (data, callback) => {
        this.setState({
            form: {...this.state.form, ...data}
        }, callback)
    };

    changeName = (e) => {
        let slug = e.target.value.toString()
            .trim()
            .toLowerCase()
            .replace(/\s+/g, "-")
            .replace(/[^\w\-]+/g, "")
            .replace(/\-\-+/g, "-")
            .replace(/^-+/, "")
            .replace(/-+$/, "")

        this.updateFormField({
            name: e.target.value,
            slug: slug,
        }, () => {
            this.setState({slugSample: `${slug}.${process.env.REACT_APP_LIVE_URL_DOMAIN}`,})
        })
    };

    change = (e) => {
        this.updateFormField({[e.target.name]: e.target.value})
    };

    checkGarageSlug = (e) => {
        const {form: {slug}} = this.state;

        axios.post(API_REPAIR_SHOP_SLUG_URL, {slug: slug}).then(response => {
            this.setState({
                slugAvailable: !response.data.exists
            })
        })
            .catch((error) => {
                this.setState({
                    slugAvailable: false
                })
            });
    };

    getCoordinates = () => {
        const {form: {address}, zip} = this.state;

        axios.post(API_GET_COORDINATES_URL, {address: `${address} ${zip.label}`}).then(response => {
            this.updateFormField({
                latitude: response.data.results.latitude,
                longitude: response.data.results.longitude
            })
        })
            .catch((error) => {
            });
    };

    submit = (e) => {
        e.preventDefault();

        if (this.validator.allValid()) {
            this.sendRegistration();
        } else {
            this.validator.showMessages();
        }
    };

    sendRegistration = () => {
        const {form} = this.state;
        const formData = new FormData();

        Object.keys(form).map(key => formData.append(key, form[key]))

        this.setState({
            submitting: {...this.state.submitting, ...{register: true}}
        })

        axios.post(API_REGISTER_URL, formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(response => {
                this.setState({
                    ...RegisterModel,
                    alertSuccess: [response.data.message],
                    submitting: {...this.state.submitting, ...{register: false}}
                });
                this.messageRef.current.scrollIntoView()
            })
            .catch((error) => {
                this.setState({
                    alertErrors: [parseResponse(error.response)],
                    submitting: {...this.state.submitting, ...{register: false}}
                })
                this.messageRef.current.scrollIntoView()
            });
    }

    slugAvailable = () => {
        const {slugAvailable} = this.state;

        if (slugAvailable === true) {
            return <IconCheck/>;
        } else if (slugAvailable === false) {
            return <IconClose/>;
        }

        return null;
    };

    zipChange = (e) => {
        this.updateFormField({zip_id: e.value}, () => {
            this.setState({
                zip: e
            }, () => this.getCoordinates())
        })
    };

    logoChange = (value) => {
        this.updateFormField({logo: value});
    };

    updateAlertSuccess = (index) => {
        const {alertSuccess} = this.state;
        let clone = Object.assign([], alertSuccess)
        clone.splice(index, 1)

        this.setState({alertSuccess: clone});
    }

    updateAlertErrors = (index) => {
        const {alertErrors} = this.state;
        let clone = Object.assign([], alertErrors)
        clone.splice(index, 1)

        this.setState({alertErrors: clone});
    }

    render() {
        const {title} = this.props;
        const {
            alertSuccess, alertErrors, submitting,
            form: {slug, name, address, zip_id, garage_phone, garage_email, web, logo, contact_first_name, contact_last_name, contact_phone, contact_email, latitude, longitude}, slugSample
        } = this.state;

        let slugAvailable = this.slugAvailable();

        return (
            <React.Fragment>

                <Helmet><title>{title}</title></Helmet>

                <Header/>

                <div className="container">
                    <div className="register">

                        <h1>Service-Rechner Bestellformular</h1>
                        <div ref={this.messageRef}>

                            {alertSuccess.map((success, i) => <Alert success={success} key={i}
                                                                     index={i}
                                                                     onClose={(index) => this.updateAlertSuccess(index)}/>)}
                            {alertErrors.map((error, i) => <Alert error={error} key={i} index={i}
                                                                  onClose={(index) => this.updateAlertErrors(index)}/>)}
                        </div>
                        <form onSubmit={this.submit}>
                            <div className="row">
                                <div className="col-lg-6">
                                    <Input
                                        type="text"
                                        placeholder={'Geben Sie den Namen der Garage ein'}
                                        name="name"
                                        label={'Name der Garage'}
                                        value={name}
                                        onChange={e => this.changeName(e)}
                                        onBlur={this.checkGarageSlug}
                                        error={this.validator.message('name', name, 'required')}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <Input
                                        type="text"
                                        name="slugSample"
                                        placeholder={`.${process.env.REACT_APP_LIVE_URL_DOMAIN}`}
                                        label={'\u00A0'}
                                        value={slugSample}
                                        onChange={e => this.change(e)}
                                        error={(this.state.slugAvailable === false ? 'Slug wird bereits verwendet.' : '')}
                                        readonly={true}
                                        rightChild={slugAvailable}
                                    />
                                    <Input
                                        type="hidden"
                                        name="slug"
                                        value={slug}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <Input
                                        type="text"
                                        placeholder={'Adresse eingeben'}
                                        name="address"
                                        label={'Adresse'}
                                        value={address}
                                        onBlur={this.getCoordinates}
                                        onChange={e => this.change(e)}
                                        error={this.validator.message('address', address, 'required')}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <ZipPicker
                                        id="zip_id"
                                        name="zip_id"
                                        label={'Postleitzahl'}
                                        value={zip_id}
                                        onChange={this.zipChange}
                                        error={this.validator.message('zip_id', zip_id, 'required')}
                                    />

                                    <Input
                                        type="hidden"
                                        name="latitude"
                                        value={latitude}
                                    />
                                    <Input
                                        type="hidden"
                                        name="longitude"
                                        value={longitude}
                                    />

                                </div>
                                <div className="col-lg-6">
                                    <Input
                                        type="text"
                                        placeholder={'Telefon eingeben'}
                                        name="garage_phone"
                                        label={'Garage Telefonnummer'}
                                        value={garage_phone}
                                        onChange={e => this.change(e)}
                                        error={this.validator.message('garage_phone', garage_phone, 'required')}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <Input
                                        type="text"
                                        placeholder={'Enter email'}
                                        name="garage_email"
                                        label={'Email eingeben'}
                                        value={garage_email}
                                        onChange={e => this.change(e)}
                                        error={this.validator.message('garage_email', garage_email, 'required|email')}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <Input
                                        type="text"
                                        placeholder={'Webseite betreten'}
                                        name="web"
                                        label={'Webseite'}
                                        value={web}
                                        onChange={e => this.change(e)}
                                        error={this.validator.message('web', web, 'required')}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <InputFile
                                        name="logo"
                                        label={'Logo'}
                                        value={logo}
                                        onChange={e => this.logoChange(e)}
                                        error={this.validator.message('logo', logo, 'required|image|size')}
                                    />
                                </div>
                            </div>

                            <hr/>

                            <div className="row">
                                <div className="col-lg-6">
                                    <Input
                                        type="text"
                                        placeholder={'Geben Sie den Vornamen des Kontakts ein'}
                                        name="contact_first_name"
                                        label={'Kontakt Vorname'}
                                        value={contact_first_name}
                                        onChange={e => this.change(e)}
                                        error={this.validator.message('contact_first_name', contact_first_name, 'required')}
                                    />

                                </div>
                                <div className="col-lg-6">
                                    <Input
                                        type="text"
                                        placeholder={'Geben Sie den Nachnamen des Kontakts ein'}
                                        name="contact_last_name"
                                        label={'Kontakt Nachname'}
                                        value={contact_last_name}
                                        onChange={e => this.change(e)}
                                        error={this.validator.message('contact_last_name', contact_last_name, 'required')}
                                    />

                                </div>
                                <div className="col-lg-6">
                                    <Input
                                        type="text"
                                        placeholder={'Kontakttelefon eingeben'}
                                        name="contact_phone"
                                        label={'Kontakt-Telefon'}
                                        value={contact_phone}
                                        onChange={e => this.change(e)}
                                        error={this.validator.message('contact_phone', contact_phone, 'required')}
                                    />

                                </div>
                                <div className="col-lg-6">
                                    <Input
                                        type="text"
                                        placeholder={'Kontakt-E-Mail eingeben'}
                                        name="contact_email"
                                        label={'Kontakt E-mail'}
                                        value={contact_email}
                                        onChange={e => this.change(e)}
                                        error={this.validator.message('contact_email', contact_email, 'required|email')}
                                    />
                                </div>
                            </div>

                            <div className="text-center mt-30">
                                <Button size="lg" type="primary" extraClass="btn-border register-button"
                                        disabled={submitting.register}>
                                    {submitting.register ? 'wird bearbeitet...' : 'Registrieren'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>

                <Footer/>
            </React.Fragment>
        )
    }
}

export default Register;
