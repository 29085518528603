import React, {Component} from 'react';
import './GoToTop.scss';
import UpArrow from "../../../assets/images/up-arrow.svg";
import Scroll from 'react-scroll'

class GoToTop extends Component {

    state = {
        visible: false
    }

    componentDidMount() {
        window.addEventListener('scroll', (e) => {
            this.setState({
                visible: window.pageYOffset > 100
            })
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className={`go-to-top ${!this.state.visible ? 'hide' : ''}`} onClick={() => Scroll.animateScroll.scrollToTop({})}>
                    <img src={UpArrow} alt="Up"/>
                </div>
            </React.Fragment>
        )
    }
}

export default GoToTop;
