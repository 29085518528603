import React, {Component} from 'react';
import './ThirdSegment.scss';
import Button from "../../../shared/Button/Button";
import howItWorksVideo from '../../../../assets/video/how-it-works.mp4';
import VisibilitySensor from "react-visibility-sensor";
import {Link} from "react-scroll";

class ThirdSegment extends Component {

    state = {
        link: {
            offset: -79, smooth: true, duration: 500
        }
    }

    visibilityChange = (visible) => {
        this.props.visibilityChange(visible, this.ref.id)
    }

    render() {
        return (
            <React.Fragment>
                <VisibilitySensor onChange={this.visibilityChange}>
                    <div className="page-segment third-segment" id="third-segment" ref={ref => this.ref = ref}>
                        <div className="container">
                            <h4>So funktionierts</h4>

                            <div className="video-tag">
                                <video preload="none" autoPlay muted loop={true} playsInline={true} src={howItWorksVideo} controls={true}/>
                            </div>

                            <div className="text-center">
                                <Button size="lg" type="primary" extraClass="btn-border">
                                    <Link to="eighth-segment" offset={this.state.link.offset} smooth={this.state.link.smooth} duration={this.state.link.duration}>Unverbindlich testen</Link>
                                </Button>
                            </div>
                        </div>
                    </div>
                </VisibilitySensor>
            </React.Fragment>
        )
    }
}

export default ThirdSegment;
