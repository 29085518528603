let history = require('history')
let historyRoute

historyRoute = history.createBrowserHistory()

const prevHistoryPush = historyRoute.push
let lastLocation = historyRoute.location

historyRoute.listen(location => {
    lastLocation = location
})
historyRoute.push = (pathname, state = {}) => {
    if (
        lastLocation === null ||
        pathname !== lastLocation.pathname + lastLocation.search + lastLocation.hash ||
        JSON.stringify(state) !== JSON.stringify(lastLocation.state)
    ) {
        prevHistoryPush(pathname, state)
    }
}

export default historyRoute
