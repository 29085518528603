import React from 'react';
import {hydrate, render} from "react-dom";
import './assets/styles/app.scss'
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom'
import historyRoute from "./routes/history";
import ReactGA from 'react-ga';

const rootElement = document.getElementById("root");

ReactGA.initialize('UA-126088763-3');

const renderApp = Component => {
    return (
        <BrowserRouter history={historyRoute}>
            <React.StrictMode><Component/></React.StrictMode>
        </BrowserRouter>
    )
}

if (rootElement.hasChildNodes()) {
    hydrate(renderApp(App), rootElement);
} else {
    render(renderApp(App), rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
