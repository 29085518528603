import React from 'react';
import {isTablet, isMobileOnly} from 'react-device-detect'
import Helmet from "react-helmet";
import {Route, Switch} from "react-router-dom";
import {routes} from "./routes/routes";
import ScrollToTop from "./components/hoc/ScrollToTop";
import '../src/app/plugins/simple-react-validator';

class App extends React.Component {
    render() {
        return (
            <div className="app">
                <Helmet>
                    <link rel="canonical" href="https://www.service-rechner.ch"/>
                    <meta property="og:title" content="HOME | Service Rechner"/>
                    <meta property="og:url" content="https://www.service-rechner.ch"/>
                    <meta property="og:site_name" content="Service Rechner"/>
                    <meta property="og:type" content="website"/>

                    {isMobileOnly &&
                    <meta name="viewport" content="width=320, user-scalable=yes"/>
                    }
                    {isTablet &&
                    <meta name="viewport" content="width=980, user-scalable=yes"/>
                    }
                </Helmet>

                <ScrollToTop>
                    <Switch>
                        {Object.keys(routes).map((route) =>
                            <Route key={route} path={routes[route].path}
                                   component={() => <RouteComponent component={routes[route].component}
                                                                    title={routes[route].pageTitle}/>}
                                   exact={routes[route].exact}/>)
                        }
                    </Switch>
                </ScrollToTop>
            </div>
        );
    }
}

const RouteComponent = ({component: Component, ...rest}) => <Component {...rest} />

export default App;
