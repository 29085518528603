import React, {Component} from 'react';
import "./Home.scss";
import Header from "../../components/layout/Header/Header";
import Cover from "../../components/layout/Cover/Cover";
import FirstSegment from "../../components/layout/PageSegments/FirstSegment/FirstSegment";
import SecondSegment from "../../components/layout/PageSegments/SecondSegment/SecondSegment";
import ThirdSegment from "../../components/layout/PageSegments/ThirdSegment/ThirdSegment";
import ForthSegment from "../../components/layout/PageSegments/ForthSegment/ForthSegment";
import FifthSegment from "../../components/layout/PageSegments/FifthSegment/FifthSegment";
import SixthSegment from "../../components/layout/PageSegments/SixthSegment/SixthSegment";
import SeventhSegment from "../../components/layout/PageSegments/SeventhSegment/SeventhSegment";
import EighthSegment from "../../components/layout/PageSegments/EighthSegment/EighthSegment";
import NinthSegment from "../../components/layout/PageSegments/NinthSegment/NinthSegment";
import Footer from "../../components/layout/Footer/Footer";
import { isMobileOnly } from 'react-device-detect'
import DotNavigation from "../../components/layout/DotNavigation/DotNavigation";
import GoToTop from "../../components/layout/GoToTop/GoToTop";
import {Helmet} from "react-helmet";
import {GApageView} from "../../utils/GApageView";

class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentSegment: 'root'
        }
    }

    componentDidMount() {
        GApageView('/home');
    }

    segmentChange = (visible, id) => {
        this.setState({
            currentSegment: id,
        })
    }

    render() {

        return (
            <React.Fragment>

                <Helmet><title>{this.props.title}</title></Helmet>

                <Header currentSegment={this.state.currentSegment}/>

                <Cover visibilityChange={this.segmentChange}/>

                <main className="main">
                    <FirstSegment visibilityChange={this.segmentChange}/>
                    <SecondSegment visibilityChange={this.segmentChange}/>
                    <ThirdSegment visibilityChange={this.segmentChange}/>
                    <ForthSegment visibilityChange={this.segmentChange}/>
                    <FifthSegment visibilityChange={this.segmentChange}/>
                    <SixthSegment visibilityChange={this.segmentChange}/>

                    {!isMobileOnly &&
                        <SeventhSegment visibilityChange={this.segmentChange}/>
                    }

                    <EighthSegment visibilityChange={this.segmentChange}/>
                    <NinthSegment visibilityChange={this.segmentChange}/>
                </main>

                {!isMobileOnly &&
                    <DotNavigation currentSegment={this.state.currentSegment}/>
                }

                {isMobileOnly &&
                    <GoToTop />
                }

                <Footer/>
            </React.Fragment>
        )
    }
}

export default Home;
