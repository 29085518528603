import React, {Component} from 'react';
import './Cover.scss';
import backgroundCover from "../../../assets/images/background.webp";
import VisibilitySensor from "react-visibility-sensor";
import {isMobileOnly} from "react-device-detect";
import {routes} from "../../../routes/routes";
import {Link} from "react-router-dom";

class Cover extends Component {

    state = {
        moveDown: 0
    }

    componentDidMount() {
        if (!isMobileOnly) {
            window.addEventListener('scroll', () => {
                this.setState({
                    moveDown: window.pageYOffset / 7
                })
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <VisibilitySensor onChange={(visible) => this.props.visibilityChange(visible, 'root')}>
                    <div className="cover-wrapper" ref={ref => this.ref = ref}>
                        <div className="cover-image"
                             style={{transform: !isMobileOnly ? 'translate3d(0px, -' + this.state.moveDown + 'px, 0px)' : 'none'}}>
                            <img src={backgroundCover} alt="backgroundCover"/>
                        </div>

                        <div className="cover-content">
                            <div className="cover-content__headline">
                                <h2>Der schnellste Weg&nbsp;</h2>
                                <h2>zur Service-Offerte</h2>
                            </div>

                            {!isMobileOnly &&
                            <div className="cover-content__link">
                                <Link className="btn btn-lg btn-primary btn-border" to={routes.register.path}>30 Tage
                                    kostenlos testen</Link>
                            </div>
                            }
                        </div>
                    </div>
                </VisibilitySensor>
            </React.Fragment>
        )
    }
}

export default Cover;
