import React, {Component} from 'react';
import './Footer.scss';
import {ReactComponent as IconPhone} from "../../../assets/icons/phone.svg";
import {ReactComponent as IconMap} from "../../../assets/icons/map.svg";
import {isMobileOnly} from "react-device-detect";
import {Link} from "react-router-dom";
import {routes} from "../../../routes/routes";

class Footer extends Component {
    render() {

        return (
            <React.Fragment>
                <footer className="footer">
                    <div className="container">
                        <div className="clearfix">
                            {!isMobileOnly &&
                                <div className="col-lg-1 col-xs-1">&nbsp;</div>
                            }
                            <div className="col-lg-3 col-xs-12">
                                <p className="description">
                                    Der Service-Rechner ist ein Produkt zur Erleichterung von Prozessen in Garagen.
                                    Durch eine schnelle Berechnung einer Service-Offerte wird der Alltag der
                                    Garagen-mitarbeiter wie auch Kunden erleichtert.
                                </p>
                            </div>
                            {!isMobileOnly &&
                                <div className="col-lg-5 col-xs-5">&nbsp;</div>
                            }
                            {isMobileOnly &&
                                <div className="col-lg-5 col-xs-1">&nbsp;</div>
                            }
                            <div className="col-lg-3 col-xs-11">
                                <div className="phone">
                                    <div><IconPhone/> <a href="tel:+41 44 545 87 78">+41 44 545 87 78</a></div>
                                    <div><a href="tel:+41 78 410 11 02">+41 78 410 11 02</a></div>
                                </div>
                                <div className="address">
                                    <div><IconMap/> Hardturmstrasse 161</div>
                                    <div>8005 Zürich</div>
                                    <div>Schweiz</div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center font-size-sm">© 2019 Service-Rechner I <Link
                            to={routes.impressum.path}>Impressum</Link></div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}

export default Footer;
