export default {
    address: '',
    contact_email: '',
    contact_first_name: '',
    contact_last_name: '',
    contact_phone: '',
    garage_email: '',
    garage_phone: '',
    latitude: '',
    logo: '',
    longitude: '',
    name: '',
    slug: '',
    web: '',
    zip_id: '',
};
