import React, { Component } from 'react';
import './InputFile.scss';
import Input from "../Input/Input";
import {ReactComponent as IconImg} from "../../../assets/icons/img.svg";

class InputFile extends Component {

    constructor(props) {
        super(props)

        this.state = {
            value: props.value
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.value && !this.props.value){
            this.setState({
                value: this.props.value,
                file: null,
            })
        }
    }

    change = (e) => {
        this.setState({
            file: e.target.files[0]
        }, () => {
            this.props.onChange(this.state.file)
        })
    }

    render() {
        const { file, value} = this.state;
        const { name, label, placeholder, error} = this.props;

        return (
          <React.Fragment>
              <div className="input-file">
                  <div className="file">
                      <label htmlFor="file-input"/>
                      <input type="file" id="file-input" value={value} accept="image/*" name={name} onChange={e => this.change(e)}/>
                  </div>

                  <Input
                    type="text"
                    placeholder={placeholder}
                    label={label}
                    value={file ? file.name : ''}
                    error={error}
                    leftChild={
                        <IconImg/>
                    }
                  />
              </div>
           </React.Fragment>
        );
    }
}

export default InputFile
