import React, {Component} from 'react';
import './FirstSegment.scss';
import mockUpDevices from "../../../../assets/images/Service-Rechner_Mock_Up_Devices.webp";
import VisibilitySensor from "react-visibility-sensor";
import {isMobileOnly} from "react-device-detect";
import {routes} from "../../../../routes/routes";
import {Link} from "react-router-dom";

class FirstSegment extends Component {

    visibilityChange = (visible) => {
        this.props.visibilityChange(visible, this.ref.id)
    }

    render() {
        return (
            <React.Fragment>
                <VisibilitySensor partialVisibility={'bottom'} onChange={this.visibilityChange}>
                    <div className="page-segment first-segment" id="first-segment" ref={ref => this.ref = ref}>
                        <div className="container">

                            <div className="mock-up-devices">
                                <img src={mockUpDevices} alt={"devices"}/>
                            </div>

                            {isMobileOnly &&
                            <div className="text-center">
                                <Link className="btn btn-lg btn-primary btn-border" to={routes.register.path}>30 Tage
                                    kostenlos testen</Link>
                            </div>
                            }

                            <h4>Was ist der Service-Rechner?</h4>
                            <p className="description">
                                Die Worte Service und Rechner verhelfen Ihnen Ihre Aufwandskosten um bis zu 90% in der
                                Kundenakquisition zu reduzieren. Der Service-Rechner bietet Ihnen ein sogenanntes
                                White-Label-Produkt auf Ihrer betriebseigenen Webseite und ist Schweizweit einzigartig.
                                Durch diesen erhalten Sie einen Online-Preisrechner, welcher Ihnen die Offertenstellung
                                enorm vereinfacht. In wenigen Minuten können&nbsp;Ihre Kunden und Sie selbst&nbsp;durch
                                Eingabe der Chassisnummer (VIN) oder durch Auswahl der Fahrzeugdaten eine detaillierte
                                Offerte inkl. Herstellerdaten und Teilenummern erstellen. Somit bleibt Ihr
                                Betrieb&nbsp;24/7 für Ihren After-Sales-Kunden erreichbar und Ihre Mitarbeiter sind das
                                zeitaufwändige Offerten schreiben los.
                            </p>

                            <div className="text-center">
                                <a href="https://service-rechner.online-autoservice.ch/#/" target="_blank"
                                   rel="noopener noreferrer"
                                   className="btn btn-lg btn-primary btn-border">Jetzt live testen</a>
                            </div>
                        </div>
                    </div>
                </VisibilitySensor>
            </React.Fragment>
        )
    }
}

export default FirstSegment;
