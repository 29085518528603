import React, {useState} from 'react';
import './Alert.scss';
import {ReactComponent as IconClose} from "../../../assets/icons/close.svg"

export const Alert = props => {

    const {onClose, index} = props;

    const [ success ] = useState(props.success);
    const [ error ] = useState(props.error);

    return (
        <>
            {success &&
                <div className="alert alert-success">{success}
                    <span className="icon" onClick={() => onClose(index)}><IconClose/></span></div>
            }

            {error &&
                <div className="alert alert-danger">{error}
                    <span className="icon" onClick={() => onClose(index)}><IconClose/></span>
                </div>
            }
        </>
    );
}

export default Alert;
