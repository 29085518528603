import React, {Component} from 'react';
import './ZipPicker.scss';
import axios from "../../../app/config/axios";
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';

const API_ZIP_FETCH_URL = 'zip'

const NoOptionsMessage = props => <components.NoOptionsMessage {...props} children={'No result'}/>
const LoadingMessage = props => <components.LoadingMessage {...props} children={'Loading'}/>
const DropdownIndicator = props => null

class ZipPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.value && !this.props.value){
            this.setState({
                value: this.props.value,
            })
        }
    }

    fetch = (input) => {

        if(!input || input.length < 4){
            return null
        }

        return axios
            .post(API_ZIP_FETCH_URL, {zip: input})
            .then(response => {
                return response.data.map(item => {
                    return {
                        value: item.id,
                        label: `${item.zip} ${item.name}`,
                    }
                })
            })
            .catch(error => {
                return []
            })
    }

    render() {
        const { name, label, onChange, error } = this.props;
        const { value } = this.state;

        return (
            <div>

                <div className={`form-group ${error ? 'has-error' : ''}`}>
                    <label htmlFor={name}>{label}</label>
                    <AsyncSelect
                        cacheOptions
                        loadOptions={this.fetch}
                        name={name}
                        onChange={(e) => {
                            this.setState({
                                value: e
                            })

                            onChange(e)
                        }}
                        value={value}
                        classNamePrefix={"select2"}
                        components={{ NoOptionsMessage, LoadingMessage, DropdownIndicator }}
                    />

                    {error && (
                        <span className="form-error">
                            {error}
                        </span>
                    )}
                </div>

            </div>
        );
    }
}

export default ZipPicker
