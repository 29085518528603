import React, { Component } from 'react';
import './input.scss';

class Input extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: props.value || ''
        };

        this.inputRef = React.createRef();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.value !== nextProps.value) {
            this.setState({
                value: nextProps.value
            })
        }
    }

    handleChange = (e, callback) => {
        if (e.target instanceof HTMLInputElement) {

            let maxLen = parseInt(e.target.maxLength);
            if(maxLen > 0 && e.target.value.toString().length > maxLen){
                return;
            }

            this.setState({ value: e.target.value });

            callback();
        }
    };

    render() {
        const { value } = this.state;
        const {
            type,
            name,
            id,
            placeholder,
            label,
            onChange,
            onClick,
            onBlur,
            onFocus,
            error,
            maxLength,
            extraClass,
            hideErrorMessage,
            autoFocus,
            hidden,
            onKeyPress,
            readonly,
            disabled,
            rightChild,
            leftChild
        } = this.props;

        if (hidden) return null;

        return (
            <div className={`form-group ${extraClass ? extraClass : ''} ${disabled ? 'disabled' : ''}`}>
                {label && <label htmlFor={name}>{label}</label>}
                <div>

                    {leftChild && (
                        <div className="input-icon input-icon--left">{leftChild}</div>
                    )}

                    <input
                        onKeyPress={onKeyPress}
                        ref={ref => this.inputRef = ref}
                        type={type}
                        name={name}
                        id={id}
                        placeholder={placeholder}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        className={`form-control ${error ? 'input-error' : ''} ${rightChild ? 'has-right-icon' : ''} ${leftChild ? 'has-left-icon' : ''}`}
                        onChange={e => {
                            this.handleChange(e, () => {
                                if (onChange) {
                                    onChange(e);
                                }
                            });
                        }}
                        onClick={() => {
                            if (onClick) {
                                onClick();
                            }
                        }}
                        value={value}
                        autoComplete="off"
                        maxLength={maxLength}
                        readOnly={readonly}
                        autoFocus={autoFocus}
                        disabled={disabled}
                    />

                    {rightChild ? (
                      <div className="input-icon input-icon--right">
                          {rightChild}
                      </div>
                    ) : null}
                </div>

                {error && !hideErrorMessage && (
                    <span className="form-error">
                        {error}
                    </span>
                )}
            </div>
        );
    }
}

export default Input;
