import React, {Component} from 'react';
import './SixthSegment.scss';
import autoSprintCh from '../../../../assets/images/about-as/auto-sprint-ch.webp';
import autoWirtschaft from '../../../../assets/images/about-as/auto-wirtschaft.webp';
import fleet from '../../../../assets/images/about-as/fleet.webp';
import {ReactComponent as Filter} from '../../../../assets/images/filter.svg';
import VisibilitySensor from "react-visibility-sensor";

class SixthSegment extends Component {

    filter = () => (
        <svg id="svgfeathered-comp-jz2ui4q0img" version="1.1" className="hide"><defs><filter id="feathered-comp-jz2ui4q0img" colorInterpolationFilters="sRGB">
            <feComponentTransfer result="srcRGB"></feComponentTransfer>
            <feColorMatrix type="saturate" values="0.2"></feColorMatrix>
            <feComponentTransfer><feFuncR type="linear" slope="0.85" intercept="0.08"></feFuncR>
                <feFuncG type="linear" slope="0.85" intercept="0.08"></feFuncG>
                <feFuncB type="linear" slope="0.85" intercept="0.08"></feFuncB></feComponentTransfer>
            <feComponentTransfer><feFuncR type="linear" slope="0.9"></feFuncR><feFuncG type="linear" slope="0.9"></feFuncG><feFuncB type="linear" slope="0.9"></feFuncB></feComponentTransfer>
            <feColorMatrix type="matrix" values="0.9098039215686274 0 0 0 0.09019607843137255 0.9294117647058824 0 0 0 0.07058823529411765 0.9294117647058824 0 0 0 0.07058823529411765 0 0 0 1 0"></feColorMatrix>
            <feComponentTransfer></feComponentTransfer>
        </filter></defs></svg>
    )

    visibilityChange = (visible) => {
        this.props.visibilityChange(visible, this.ref.id)
    }

    render() {
        return (
            <React.Fragment>
                <VisibilitySensor onChange={this.visibilityChange}>
                    <div className="page-segment sixth-segment" id="sixth-segment" ref={ref => this.ref = ref}>
                    <div className="container">
                        <h4>Über uns</h4>

                        <p className="description font_8">
                            Hinter dem Service-Rechner steht ein Team von erfahrenen Automechanikern, die den Garagen mit online Lösungen helfen das grosse Potenzial des Internets erfolgreich umzusetzen. Für das Service-Rechner Team steht der persönliche Kontakt immer im Zentrum, online Lösungen stellen dabei nur ein Hilfsmittel dar, welches im digitalen Zeitalter aber unbedingt braucht. Bitte kontaktieren Sie uns um mehr über uns zu erfahren, wir freuen uns über jede Anfrage!
                        </p>

                        <div className="text-center font_4 font-size-2">In den Medien</div>

                        <div className="media-images">
                            <div className="media-image-item">
                                <Filter />
                                <img src={autoWirtschaft} alt="autoWirtschaft"/>
                            </div>
                            <div className="media-image-item">
                                <Filter />
                                <img src={autoSprintCh} alt="autoSprintCh"/>
                            </div>
                            <div className="media-image-item">
                                <Filter />
                                <img src={fleet} alt="fleet"/>
                            </div>
                            <div className="media-image-item">
                                <Filter />
                                <img src={autoWirtschaft} alt="autoWirtschaft"/>
                            </div>
                        </div>
                    </div>
                </div>
                </VisibilitySensor>
            </React.Fragment>
        )
    }
}

export default SixthSegment;
