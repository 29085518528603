import React, {Component} from 'react';
import './ForthSegment.scss';
import serviucerechner from '../../../../assets/images/serviucerechner.webp'
import VisibilitySensor from "react-visibility-sensor";
import {isMobileOnly} from 'react-device-detect'
import {routes} from "../../../../routes/routes";
import {Link} from "react-router-dom";

class ForthSegment extends Component {

    visibilityChange = (visible) => {
        this.props.visibilityChange(visible, this.ref.id)
    }

    render() {
        return (
            <React.Fragment>
                <VisibilitySensor onChange={this.visibilityChange}>
                    <div className="page-segment forth-segment" id="forth-segment" ref={ref => this.ref = ref}>
                        <div className="container">
                            <h4>Garagen-Dashboard</h4>

                            <p className="description">
                                Mit dem entwickelten Garagen-Dahsboard, haben Sie die volle Kontrolle über Ihren
                                Service-Rechner und erhalten Informationen über Nutzung, Auftragseingänge oder ändern
                                Preise und Text.
                            </p>

                            <div className="image-holder">
                                <img src={serviucerechner} alt="serviucerechner"/>
                            </div>

                            {isMobileOnly &&
                            <div className="text-center">
                                <Link className="btn btn-lg btn-primary btn-border" to={routes.register.path}>30 Tage
                                    kostenlos testen</Link>
                            </div>
                            }
                        </div>
                    </div>
                </VisibilitySensor>
            </React.Fragment>
        )
    }
}

export default ForthSegment;
