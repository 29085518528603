import React, {Component} from 'react';
import './DotNavigation.scss';
import { Link } from 'react-scroll'
import {ReactComponent as IconDot} from "../../../assets/icons/dot.svg";

class DotNavigation extends Component {

    state = {
        link: {
            offset: -79, smooth: true, duration: 500
        },
        onTop: true
    }

    componentDidMount() {
        window.addEventListener('scroll', () => {
             this.setState({
                 onTop: window.pageYOffset < 100
             })
        })
    }

    render() {
        const {currentSegment} = this.props;

        return (
            <React.Fragment>

                <div className={`dot-navigation ${this.state.onTop ? 'dot-navigation--on-top' : ''}`}>
                    <Link className={`dot-navigation__item ${currentSegment === 'first-segment' ? 'active' : ''}`} to="first-segment" offset={this.state.link.offset} smooth={this.state.link.smooth} duration={this.state.link.duration}>
                        <IconDot/>
                    </Link>
                    <Link className={`dot-navigation__item ${['third-segment', 'forth-segment'].indexOf(currentSegment) !== -1 ? 'active' : ''}`} to="third-segment" offset={this.state.link.offset} smooth={this.state.link.smooth} duration={this.state.link.duration}>
                        <IconDot/>
                    </Link>
                    <Link className={`dot-navigation__item ${currentSegment === 'fifth-segment' ? 'active' : ''}`} to="fifth-segment" offset={this.state.link.offset} smooth={this.state.link.smooth} duration={this.state.link.duration}>
                        <IconDot/>
                    </Link>
                    <Link className={`dot-navigation__item ${currentSegment === 'sixth-segment' ? 'active' : ''}`} to="sixth-segment" offset={this.state.link.offset} smooth={this.state.link.smooth} duration={this.state.link.duration}>
                        <IconDot/>
                    </Link>
                    <Link className={`dot-navigation__item ${['seventh-segment', 'ninth-segment'].indexOf(currentSegment) !== -1 ? 'active' : ''}`} to="eighth-segment" offset={this.state.link.offset} smooth={this.state.link.smooth} duration={this.state.link.duration}>
                        <IconDot/>
                    </Link>
                </div>

            </React.Fragment>
        )
    }
}

export default DotNavigation;
