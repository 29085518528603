import React, {Component} from 'react';
import './EighthSegment.scss';
import start from '../../../../assets/images/customer-options/start.webp';
import pro from '../../../../assets/images/customer-options/pro.webp';
import expert from '../../../../assets/images/customer-options/expert.webp';
import VisibilitySensor from "react-visibility-sensor";
import {routes} from "../../../../routes/routes";
import {Link} from "react-router-dom";

class EighthSegment extends Component {

    visibilityChange = (visible) => {
        this.props.visibilityChange(visible, this.ref.id)
    }

    render() {
        return (
            <React.Fragment>
                <VisibilitySensor onChange={this.visibilityChange}>
                    <div className="page-segment eighth-segment" id="eighth-segment" ref={ref => this.ref = ref}>
                        <div className="container">
                            <div className="options">
                                <div className="option-item">
                                    <h4 className="option-item__head">START</h4>
                                    <div className="option-item__image"><img src={start} alt="start"/></div>
                                    <div className="option-item-content">
                                        <div className="option-item-content__price">
                                            <h4>bis zu</h4>
                                            <h4 className="price">50</h4>
                                            <h4>Berechnungen/Monat</h4>
                                        </div>
                                        <div className="option-item-content__list">
                                            <ul>
                                                <li>Optimal für kleine Garagen</li>
                                                <li>Business Support</li>
                                                <li>6 Monate Mindestlaufzeit</li>
                                            </ul>
                                        </div>
                                        <div className="option-item-content__total">
                                            <div>CHF 69.-/Monat</div>
                                            <div className="tax">exkl. MwSt</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="option-item">
                                    <h4 className="option-item__head">PRO</h4>
                                    <div className="option-item__image"><img src={pro} alt="pro"/></div>
                                    <div className="option-item-content">
                                        <div className="option-item-content__price">
                                            <h4>bis zu</h4>
                                            <h4 className="price">100</h4>
                                            <h4>Berechnungen/Monat</h4>
                                        </div>
                                        <div className="option-item-content__list">
                                            <ul>
                                                <li>Empfohlen für mittelgrosse Betriebe</li>
                                                <li>Business Support</li>
                                                <li>6 Monate Mindestlaufzeit</li>
                                            </ul>
                                        </div>
                                        <div className="option-item-content__total">
                                            <div>CHF 99.-/Monat</div>
                                            <div className="tax">exkl. MwSt</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="option-item">
                                    <h4 className="option-item__head">EXPERT</h4>
                                    <div className="option-item__image"><img src={expert} alt="expert"/></div>
                                    <div className="option-item-content">
                                        <div className="option-item-content__price">
                                            <h4>&nbsp;</h4>
                                            <h4 className="price">unlimitierte</h4>
                                            <h4>Berechnungen/Monat</h4>
                                        </div>
                                        <div className="option-item-content__list">
                                            <ul>
                                                <li>Sorglospaket für grössere Betriebe</li>
                                                <li>Business Support</li>
                                                <li>6 Monate Mindestlaufzeit</li>
                                            </ul>
                                        </div>
                                        <div className="option-item-content__total">
                                            <div>CHF 149.-/Monat</div>
                                            <div className="tax">exkl. MwSt</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                <div className="text-center">
                                    <Link className="btn btn-lg btn-primary btn-border" to={routes.register.path}>30
                                        Tage kostenlos testen</Link>
                                </div>
                            }
                        </div>
                    </div>
                </VisibilitySensor>
            </React.Fragment>
        )
    }
}

export default EighthSegment;
